import { FC } from 'react';
import type * as RHF from 'react-hook-form';
import { useController } from 'react-hook-form';

import config from '~/config';

import { FormItemProps } from './FormItem';

type Fn<P> = (props: P, controller: RHF.UseControllerReturn) => JSX.Element;

/**
 * 下記のようにcontrol/nameを指定するだけでform系コンポーネントが hook formと簡単に連携できるようになるutil関数

 * ```ts
 * <FormInput.ForHookForm
 *   label='タイトル'
 *   control={form.control}
 *   name='title'
 *   rules={{ required: '必須です' }}
 * />
 * ```
 * - nameの箇所はuseFormを参考に型推論される
 * - propsは元のコンポーネントから引き継がれる
 * 定義する際は以下のように記述する
 * ```ts
 * defineHookFormComponent<Props>(
 *   'FormInput.ForHookForm',
 *   (props, { field, fieldState }) => (
 *     <FormInput {...props} {...field} error={fieldState.error?.message} />
 *   ),
 * )
 * ```
 */
export function defineHookFormComponent<P>(componentName: string, fn: Fn<P>) {
  type OmittedProps<P> = Omit<P, 'value' | 'defaultValue' | 'onChange' | 'onBlur' | 'error'>;

  const Component = <T extends Record<string, any>, N extends RHF.Path<T>>(
    p: OmittedProps<P> & RHF.UseControllerProps<T, N>,
  ) => {
    let { name, rules, shouldUnregister, defaultValue, control, ...props } = p;
    if ((props as FormItemProps).isRequired) {
      rules = { required: '必須です', ...rules };
    }
    const controller = useController({ name, rules, shouldUnregister, defaultValue, control });
    ///@ts-expect-error ts(2352) 型引数の制約エラーを回避している
    return fn(props as P, controller as RHF.UseControllerReturn);
  };
  if (config.env !== 'production') {
    (Component as FC).displayName = componentName;
  }
  return Component;
}
