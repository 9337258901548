import React, { ComponentProps, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { HtmlTagProps } from '~/utils/types/HtmlTagProps';

import { cssCommonInput } from './FormInput';
import { FormItemProps, FormItem } from './FormItem';
import { defineHookFormComponent } from './defineHookFormComponent';

type FormTextAreaProps = FormItemProps & {
  height?: string;
};

type Props = HtmlTagProps['textarea'] & FormTextAreaProps;

const FormTextAreaBase = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  return (
    <FormItem {...props}>
      <StyledTextArea {...props} ref={ref} />
    </FormItem>
  );
});

FormTextAreaBase.displayName = 'FormTextArea';

const FormTextArea = Object.assign(FormTextAreaBase, {
  ForHookForm: defineHookFormComponent<Props>(
    'FormTextArea.ForHookForm',
    (props, { field, fieldState }) => (
      <FormTextAreaBase {...props} {...field} error={fieldState.error?.message} />
    ),
  ),
});

export default FormTextArea;

const StyledTextArea = styled.textarea<FormTextAreaProps>`
  ${cssCommonInput}
  padding: 16px 20px;
  height: ${(props) => props.height ?? '150px'};
  resize: vertical;
`;
