import React, { Component } from 'react';
import styled from 'styled-components';

import { createObjectURL } from '~/utils';

class FormCover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: props.value || null,
    };

    this.input = React.createRef();

    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  preview() {
    const { url } = this.state;

    if (!url) return <DefaultPreview />;

    return <Preview src={url} />;
  }

  onClick() {
    this.input.current.click();
  }

  onChange(e) {
    const { onChange } = this.props;

    const { files } = e.target;
    const file = files[0];

    if (!file) return;

    const url = createObjectURL(file);

    this.setState(
      {
        url,
      },
      () => {
        onChange(file);
      },
    );
  }

  render() {
    return (
      <Wrapper onClick={this.onClick}>
        <Label>画像アップロード</Label>
        {this.preview()}
        <Input ref={this.input} type='file' accept='image/*' onChange={this.onChange} />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  margin-top: 15px;

  &:hover {
    cursor: pointer;
  }
`;

const Label = styled.div`
  color: #505050;
  font-size: 16px;
  margin-bottom: 15px;
  text-decoration: underline;
`;

const DefaultPreview = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 20px;
  background-color: #fff;
`;

const Preview = styled.img`
  width: 100%;
  height: 400px;
  border-radius: 20px;
  overflow: hidden;
  object-fit: cover;
`;

const Input = styled.input`
  display: none;
`;

export default FormCover;
