import React from 'react';
import styled from 'styled-components';

export default function FormUidInput({ onChange, value }) {
  return (
    <Wrapper>
      <Prefix>https://susnet.jp/orgs/</Prefix>
      <FormInput value={value} onChange={onChange} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
`;

const Prefix = styled.div`
  background: #707070;
  height: 62px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  color: #fff;
  border-radius: 15px 0 0 15px;
  line-height: 62px;
  font-weight: bold;
`;

const FormInput = styled.input`
  padding-left: 20px;
  padding-right: 20px;
  width: calc(100% - 40px);
  height: 60px;
  background-color: #fff;
  border-radius: 0 15px 15px 0;
  border: none;
  color: #000;
  font-size: 16px;

  &:focus {
    width: calc(100% - 46px);
    height: 54px;
    padding-left: 17px;
    outline: none;
    border: 3px #707070 solid;
  }

  &:disabled {
    background-color: #ddd;
  }
`;
