import React, { Component } from 'react';
import styled from 'styled-components';

import { range } from '~/utils';

class FormSdgsPicker extends Component {
  constructor(props) {
    super(props);

    // FIXME: Remove state and use only props
    this.state = {
      selected: props.value || [],
    };

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(i) {
    const { number, onChange } = this.props;
    const { selected } = this.state;

    var isSelected = false;
    var count = 0;

    const newSelected = selected.map((n) => {
      if (n === i) {
        isSelected = true;
        return null;
      }

      if (n != null) count++;

      if (count >= number) return null;

      return n;
    });

    this.setState(
      {
        selected: isSelected ? newSelected : newSelected.concat(i),
      },
      () => {
        onChange(
          (isSelected ? newSelected : newSelected.concat(i)).filter(
            (n) => n !== null && n !== undefined,
          ),
        );
      },
    );
  }

  render() {
    const { selected } = this.state;

    const images = range(1, 18)
      .map((i) => {
        return (
          <SdgsImage
            key={i}
            src={`/images/sdgs/${i}.png`}
            active={!!selected.find((n) => n === i)}
            onClick={() => this.onSelect(i)}
          />
        );
      })
      .concat(range(0, 10).map((i) => <Empty key={'e-' + i} />));

    return <Wrapper>{images}</Wrapper>;
  }
}

const Wrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Empty = styled.div`
  width: 130px;
`;

const SdgsImage = styled.img`
  width: 130px;
  height: 130px;
  margin-top: 5px;
  transition: all 0.3s;
  filter: ${(props) => (props.active ? 'none' : 'grayscale(1)')};
  opacity: ${(props) => (props.active ? '1' : '0.5')};
`;

export default FormSdgsPicker;
