import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { COLOR_ERROR } from '~/colors/common';
import { HtmlTagProps } from '~/utils/types/HtmlTagProps';

import { FormItem, FormItemProps } from './FormItem';
import { defineHookFormComponent } from './defineHookFormComponent';

type Props = HtmlTagProps['input'] & FormItemProps;

const FormInputBase = forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <FormItem {...props}>
      <StyledInput {...props} ref={ref} autoComplete={props.autoComplete ?? 'off'} />
    </FormItem>
  );
});

FormInputBase.displayName = 'FormInput';

const FormInput = Object.assign(FormInputBase, {
  ForHookForm: defineHookFormComponent<Props>(
    'FormInput.ForHookForm',
    (props, { field, fieldState }) => (
      <FormInputBase {...props} {...field} error={fieldState.error?.message} />
    ),
  ),
});

export default FormInput;

export const cssCommonInput = css`
  padding: 12px 16px;
  width: 100%;

  background-color: #fff;
  border-radius: 16px;
  border-width: 3px;
  border-color: transparent;
  border-style: solid;
  transition: border 0.2s ease-out;
  color: #000;
  font-size: 16px;
  line-height: 1.4;

  .isError & {
    border-color: ${COLOR_ERROR} !important;
  }

  &:focus {
    outline: none;
    border-color: #707070;
  }

  &:disabled {
    background-color: #ddd;
  }
`;

const StyledInput = styled.input`
  ${cssCommonInput}
`;
