import styled, { css } from 'styled-components';

const FormLabel = styled.h4<{ required?: boolean }>`
  margin-top: 30px;
  margin-left: 15px;
  font-size: 16px;
  font-weight: bold;

  ${(props) =>
    props.required &&
    css`
      &:after {
        content: '必須';
        float: right;
        color: #ff0000;
      }
    `}
`;

export default FormLabel;
