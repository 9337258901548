import styled from 'styled-components';

const FormBlockInputWrapper = styled.div`
  margin-left: 30px;
  width: 100%;

  &:first-child {
    margin-left: 0;
  }
`;

export default FormBlockInputWrapper;
