import React, { Component } from 'react';
import styled from 'styled-components';

class FormCheckbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.value || false,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    const { onChange } = this.props;
    const { checked } = this.state;

    const newChecked = !checked;

    this.setState(
      {
        checked: newChecked,
      },
      () => {
        onChange(newChecked);
      },
    );
  }

  render() {
    const { label } = this.props;
    const { checked } = this.state;

    return (
      <Wrapper>
        <Input type='checkbox' onChange={this.onChange} checked={checked} />
        <Text>{label}</Text>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
`;

const Input = styled.input``;

const Text = styled.div`
  margin-left: 10px;
  color: #525252;
  font-size: 16px;
  font-weight: bold;
`;

export default FormCheckbox;
