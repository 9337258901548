import clsx from 'clsx';
import type * as CSS from 'csstype';
import React, { PropsWithChildren, ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { COLOR_BACKGROUND_BLACK, COLOR_ERROR } from '~/colors/common';
import { HtmlTagProps } from '~/utils/types/HtmlTagProps';

import { FormItem, StyledFormItem } from './FormItem';
import FormLabel from './FormLabel';

type ButtonTagProps = HtmlTagProps['button'];

type Props = PropsWithChildren &
  ButtonTagProps & {
    /** @default "100%" */
    width?: CSS.Property.Width;
    /** @default "button" ※ buttonタグ標準は "submit"  */
    type?: ButtonTagProps['type'];
    /** @default "medium" */
    size?: keyof typeof FontSize;
    rounded?: boolean;
    icon?: ReactElement;
    /** @default "default" */
    variant?: keyof typeof Variants;
    /**
     * デフォルトでは{@link FormItem} / {@link FormLabel}の下にFormButtonを並べると自動的に`margin-top`が指定される。
     *
     * noAutoMarginを指定するとそれを無効にする
     */
    noAutoMargin?: boolean;
  };

export default function FormButton(props: Props) {
  return (
    <StyledButton
      {...props}
      className={clsx(props.className, { isAutoMargin: !props.noAutoMargin })}
      type={props.type ?? 'button'}
      variant={props.variant}
    >
      {props.icon}
      <span>{props.children}</span>
    </StyledButton>
  );
}

const StyledButton = styled.button<Props>`
  padding: ${(props) => (props.size === 'xs' ? '0 12px' : '0 1.5em')};
  width: ${(props) => props.width ?? '100%'};
  // font-size 16pxの時 3.75emは60px
  height: ${(props) => (props.size === 'xs' ? '2.75em' : '3.25em')};
  border: none;
  border-radius: ${(props) => (props.rounded ? '30px' : '15px')};
  font-size: ${(props) => FontSize[props.size ?? 'medium']};
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  transition: 0.3s;
  user-select: none;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }

  ${(props) => Variants[props.variant ?? 'default']}

  ${StyledFormItem} + &.isAutoMargin,
  ${FormLabel} + &.isAutoMargin {
    margin-top: 16px;
  }
`;

const FontSize = {
  large: '18px',
  medium: '16px',
  small: '12px',
  xs: '12px',
};

const Variants = {
  default: css`
    background-color: ${COLOR_BACKGROUND_BLACK};
    color: #fff;
    :hover {
      background-color: #393939;
    }
    :disabled {
      color: #9e9e9e;
      :hover {
        background-color: ${COLOR_BACKGROUND_BLACK};
      }
    }
  `,
  ghost: css`
    background-color: transparent;
    color: #222;
    :hover {
      background-color: rgba(0, 0, 0, 10%);
    }
    :disabled {
      color: #9e9e9e;
      :hover {
        background-color: transparent;
      }
    }
  `,
  'white-outline': css`
    background-color: #fff;
    color: #222;
    border: 1px #222 solid;
    :hover {
      background-color: #ebebeb;
    }
    :disabled {
      color: #9e9e9e;
      :hover {
        background-color: #fff;
      }
    }
  `,
  danger: css`
    background-color: ${COLOR_ERROR};
    color: white;
    &:hover,
    &:disabled {
      background-color: #c1242c;
    }
  `,
};
