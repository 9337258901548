import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { COLOR_BACKGROUND_ERROR, COLOR_ERROR } from '~/colors/common';
import { COLOR_TEXT_MAIN } from '~/colors/text';

export type FormItemProps = {
  className?: string;
  label?: string;
  description?: string;
  isNewLineDescription?: boolean;
  error?: string;
  isOptional?: boolean;
  /** 指定するとhook formを使用したとき自動でrequiredが設定される */
  isRequired?: boolean;
  noErrorSpace?: boolean;
  noAutoMargin?: boolean;
  background?: string;
};

type Props = PropsWithChildren & FormItemProps;

export function FormItem(props: Props) {
  const className = clsx(props.className, {
    isAutoMargin: !props.noAutoMargin,
    isError: Boolean(props.error),
  });

  return (
    <StyledFormItem {...props} className={className}>
      {(props.label || props.description) && (
        <StyledLabelContainer>
          {props.label && <StyledLabel>{props.label}</StyledLabel>}
          {props.isOptional && <StyledTag className='isOptional'>任意</StyledTag>}
          {props.isRequired && <StyledTag className='isRequired'>必須</StyledTag>}
          {props.description && !props.isNewLineDescription && (
            <StyledDescription>{props.description}</StyledDescription>
          )}
        </StyledLabelContainer>
      )}
      {props.description && props.isNewLineDescription && (
        <StyledDescription className='isNewLineDescription'>{props.description}</StyledDescription>
      )}
      <StyledChildrenContainer>{props.children}</StyledChildrenContainer>
      {!props.noErrorSpace && <StyledError>{props.error}</StyledError>}
    </StyledFormItem>
  );
}

export const StyledFormItem = styled.div<FormItemProps>`
  width: 100%;

  ${(props) => {
    const { background } = props;
    if (!background) return;
    return css`
      padding-top: 8px;
      background: ${background};
      border-radius: 6px;
    `;
  }}

  & + &.isAutoMargin {
    margin-top: 8px;
  }
`;

const StyledLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${COLOR_TEXT_MAIN};
`;

const StyledLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  flex-shrink: 0;
`;

const StyledTag = styled.div`
  flex-shrink: 0;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  user-select: none;

  &.isOptional {
    color: #525252;
    background-color: #c0c0c0;
  }
  &.isRequired {
    color: ${COLOR_ERROR};
    background-color: ${COLOR_BACKGROUND_ERROR};
  }
`;

const StyledDescription = styled.div`
  font-size: 12px;
  color: #525252;
  &.isNewLineDescription {
    margin-top: 4px;
  }
`;

const StyledChildrenContainer = styled.div`
  * + & {
    margin-top: 12px;
  }
`;

const StyledError = styled.div`
  margin-top: 4px;
  margin-left: 10px;
  min-height: 12px;
  font-size: 12px;
  font-weight: 100;
  color: ${COLOR_ERROR};
`;
