import styled from 'styled-components';

const FormNote = styled.span`
  margin-left: 20px;
  font-size: 14px;
  color: #525252;
  font-weight: normal;
`;

export default FormNote;
