import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { HtmlTagProps } from '~/utils/types/HtmlTagProps';

import { cssCommonInput } from './FormInput';
import { FormItem, FormItemProps } from './FormItem';
import { defineHookFormComponent } from './defineHookFormComponent';

type OptionType =
  | string
  | number
  | {
      key: string | number;
      value: string | number;
    };

type Props = HtmlTagProps['select'] &
  FormItemProps & {
    enableEmpty?: boolean;
    placeholder?: string;
    values?: OptionType[];
  };

const FormSelectBase = forwardRef<HTMLSelectElement, Props>((_props, ref) => {
  const { enableEmpty, placeholder, values, ...props } = _props;

  return (
    <FormItem {...props}>
      <Select {...props} ref={ref} disabled={!values || props.disabled}>
        <option key={'default-value'} value='' style={{ display: enableEmpty ? 'block' : 'none' }}>
          {placeholder || '▼選択する'}
        </option>
        {values?.map((v) =>
          typeof v === 'object' ? (
            <option key={v.key} value={v.value}>
              {v.key}
            </option>
          ) : (
            <option key={v} value={v}>
              {v}
            </option>
          ),
        )}
      </Select>
    </FormItem>
  );
});

FormSelectBase.displayName = 'FormSelect';

const FormSelect = Object.assign(FormSelectBase, {
  ForHookForm: defineHookFormComponent<Props>(
    'FormSelect.ForHookForm',
    (props, { field, fieldState }) => (
      <FormSelectBase {...props} {...field} error={fieldState.error?.message} />
    ),
  ),
});

export default FormSelect;

const Select = styled.select`
  ${cssCommonInput}

  user-select: none;
  appearance: none;
  text-align: center;
`;
