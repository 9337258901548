import styled from 'styled-components';

const FormSection = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
  font-size: 24px;
  font-weight: bold;
`;

export default FormSection;
