import React, { Component } from 'react';
import styled from 'styled-components';

class FormAgreement extends Component {
  constructor() {
    super();

    this.state = {
      checked: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    const { onChange } = this.props;
    const { checked } = this.state;

    const newChecked = !checked;

    this.setState(
      {
        checked: newChecked,
      },
      () => {
        onChange(newChecked);
      },
    );
  }

  render() {
    const { color, linkColor } = this.props;
    const { checked } = this.state;

    return (
      <Wrapper>
        <Input type='checkbox' onChange={this.onChange} checked={checked} />
        <Text color={color} linkColor={linkColor}>
          <a href='/terms' target='_blank'>
            利用規約
          </a>
          ・
          <a href='/privacy' target='_blank'>
            プライバシーポリシー
          </a>
          に同意する
        </Text>
      </Wrapper>
    );
  }
}

const Wrapper = styled.label`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Input = styled.input`
  margin: 0;
`;

const Text = styled.div`
  margin-bottom: 1px;
  margin-left: 10px;
  color: ${(props) => props.color || '#525252'};
  font-size: 14px;
  user-select: none;

  a {
    text-decoration: underline;
    color: ${(props) => props.linkColor || 'inherit'};
  }
`;

export default FormAgreement;
